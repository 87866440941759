

































































































































































































import { Component } from 'vue-property-decorator';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import Util from '@/utils/Util';
import { Icon } from '@/model/util/Icon';
import { internet } from '@/utils/Internet';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { utils, writeFile } from 'xlsx';
@Component({
		name: 'MainTags',
        components: {
			AppContainerBase
    }})

export default class MainTags extends MixinMain{
	public file: File | undefined = undefined;
	public audioCargado = false;
	public dialog_progress = false;
	public dialog_info = false;
	//Textos 
	public title = this.$t("MainTags.title");
	public d_info = this.$t("MainTags.d_info");
	public d_title = this.$t("MainTags.d_title");
	public selectAudio = this.$t("MainTags.selectAudio");
	public btnCalcular = this.$t("MainTags.btnCalcular");
	public btnLimpiar = this.$t("MainTags.btnLimpiar");
	public dialogProgress = this.$t("MainTags.dialogProgress");
	public evaluar = this.$t("MainTags.evaluar");
	public Filtro = this.$t("MainTags.Filtro");
	public resultado = this.$t("MainTags.resultado");
	public porcentaje = this.$t("MainTags.porcentaje");
	public text_initial = this.$t("MainTags.text_initial");
	public text_end = this.$t("MainTags.text_end");
	public text_total = this.$t("MainTags.text_total");
	public text_ruido = this.$t("MainTags.text_ruido");
	public countElementPage = this.$t("DataTable.countElementPage");
	public elementPerPage = this.$t("DataTable.elementPerPage");
	public table_name = this.$t("MainTagsTable.audioName") as string;
	public table_duration = this.$t("MainTagsTable.duration") as string;
	public table_noise = this.$t("MainTagsTable.noise") as string;
	public table_waitTime = this.$t("MainTagsTable.waitTime") as string;
	public table_finalSilence = this.$t("MainTagsTable.finalSilence") as string;
	public table_totalSilence = this.$t("MainTagsTable.totalSilence") as string;
	public subtitle = this.$t("MainTags.subtitle");
	//Switches
	public check_final = false;
	public check_inicial = false;
	public check_total = false;
	public check_ruido = false;
	//Silencio
	public inicial = "-";
	public inicial_porcentaje = "-";
	public final = "-";
	public final_porcentaje = "-";
	public total = "-";
	public total_porcentaje = "-";
	//Ruido
	public ruido_porcentaje = "-";
	public ruido = "-";
	//Descarga 
	public isLoading = false;
	public isDialog = false;
	public isEnabled = true;
	public buttonText = this.$t("ButtonDownload.exportButtonText");
	//Tabla
	public rows: Array<IDataTable> = [];
    public header: Array<IHeaderTable<IDataTable>> = [
		{text: this.table_name, align: 'center', value: this.table_name},
		{text: this.table_duration, align: 'center', value: this.table_duration},
		{text: this.table_noise, align: 'center', value: this.table_noise},
		{text: this.table_waitTime, align: 'center', value: this.table_waitTime},
		{text: this.table_finalSilence, align: 'center', value: this.table_finalSilence},
		{text: this.table_totalSilence, align: 'center', value: this.table_totalSilence}
	];

	mounted() {
		this.getData();
	}

	public async getData() {
		const { data } = await internet.newRequest().get('/noiseSilence/getNoiseSilence');
		//console.log("llamada", data);
		const dataTable: Array<IDataTable> = [];
		for(let item of data) {
			const row: IDataTable = {};
			row[this.table_name] = item.audio_name;
			row[this.table_duration] = item.audio_duration.toFixed(1);
			if (item.noise_value != -1) {
				row[this.table_noise] = `${item.noise_value.toFixed(1)} (${item.noise_percentage.toFixed(1)}%)`;
			}
			if (item.wait_time_value != -1) {
				row[this.table_waitTime] = `${item.wait_time_value.toFixed(1)} (${item.wait_time_percentage.toFixed(1)}%)`;
			}
			if (item.final_silence_value != -1) {
				row[this.table_finalSilence] = `${item.final_silence_value.toFixed(1)} (${item.final_silence_percentage.toFixed(1)}%)`;
			}
			if (item.total_silence_value != -1) {
				row[this.table_totalSilence] = `${item.total_silence_value.toFixed(1)} (${item.total_silence_percentage.toFixed(1)}%)`;
			}
			dataTable.push(row);
		}
		this.rows = dataTable;
	}

	public async submitFile(){
		console.log(this.$refs.calc);
		if (this.$refs.calc != undefined) {
			(this.$refs.calc as HTMLInputElement).disabled = true;
		}
		if (this.$refs.file != undefined) {
			(this.$refs.file as HTMLInputElement).disabled = true;
		}

        let formData = new FormData();
        formData.append('files', this.file as File);

        try {
			
			if ( !this.check_final  &&
			!this.check_inicial  && 
			!this.check_total &&
			!this.check_ruido ) {
				Util.showMessage(
					this.$t("MainTags.warning") as string,
					Icon.INFO)
					//this.messageError( 'Debe Seleccionar una opción')
					return;
				}

			this.dialog_progress = true;
			
			if ( this.check_inicial ) {
				const { data } = await internet.newRequest().post( '/noiseSilence/postWaitTime', formData )
				if( !data.msg ) {
					this.inicial = data.resultado.toFixed(1);
					this.inicial_porcentaje = data.porcentaje.toFixed(1);
				} else {
					this.dialog_progress = false;
					return this.messageError( data.msg );
				}
			}
          if ( this.check_final) {
            const { data } = await internet.newRequest().post( '/noiseSilence/postFinalSilence', formData )
            if( !data.msg ) {
				this.final = data.resultado.toFixed(1);
				this.final_porcentaje = data.porcentaje.toFixed(1);
            } else {
				this.dialog_progress = false;
				return this.messageError( data.msg );
            }
          }
          if ( this.check_total ) {
            const { data } = await internet.newRequest().post( '/noiseSilence/postTotalSilence', formData )
            if( !data.msg ) {
				this.total = data.resultado.toFixed(1);
				this.total_porcentaje = data.porcentaje.toFixed(1);
            } else {
				this.dialog_progress = false;
				return this.messageError( data.msg );
            }
        }

          if ( this.check_ruido ) {
            const { data } = await internet.newRequest().post( '/noiseSilence/postNoice', formData )
            if( !data.msg ) {
				this.ruido = data.resultado.toFixed(1);
				this.ruido_porcentaje = data.porcentaje.toFixed(1);
            } else {
				this.dialog_progress = false;
				return this.messageError( data.msg );
            }
		}
		
		//this.$refs.reset.disabled = false;
		this.dialog_progress = false;
          //Swal.close()
     
        } catch (error) {
			this.dialog_progress = false;
			Util.showMessage(
				this.$t("MainTags.msgError") as string, Icon.ERROR
			);
        }
    }
	

	public handleFileUpload(){
		this.audioCargado = true
	}

	public messageError( msg: string ) {
		Util.showMessage(
			this.$t("MainTags.msgError2") as string,
			Icon.INFO
			);
			this.reset()
		}

	public reset() {
        
		if (this.$refs.file != undefined) {
			(this.$refs.file as HTMLInputElement).disabled = false;
		}

		this.check_final = false;
		this.check_inicial = false;
		this.check_total = false;
		this.check_ruido = false;

        this.file = undefined;
		this.inicial = "-";
		this.inicial_porcentaje = "-";
		this.final = "-";
		this.final_porcentaje = "-";
		this.total = "-";
		this.total_porcentaje = "-";
		this.ruido_porcentaje = "-";
		this.ruido = "-";
    }

	public getTextCountElementPerPage(
			to: number,
			end: number
		): string {
			let text = Util.replaceTextWith(
				this.countElementPage as string,
				'$1',
				to.toString()
			);
			text = Util.replaceTextWith(
				text,
				'$2',
				end.toString()
			);
			return text;
		}

	public descargarExcel(){
		const request = internet 
		.newRequest()
		.get("exportable/silencios")
		Util.waitForPromises([request])
		.then((response) => {
			console.log(response[0])
			const data_1 = response[0].data as Array<Record<string, string>>
			const order_1 = [
				'Nombre_audio',
				'Duracion',
				'Silencio_inicial',
				'Silencio_final',
				'Cumple',
			];
			const result_1: Array<Record<
				string,
				string
			>> = data_1.map(
				(
					obj: Record<string, string>
				): Record<string, string> => {
					const exit: Record<string, string> = {};
					for (let order of order_1) {
						exit[
							Util.replaceTextWith(
								order,
								'_',
								' '
							).toUpperCase()
						] = obj[order.toLowerCase()];
					}
					return exit;
				}
			);
			const audio = utils.json_to_sheet(result_1);
			const book = utils.book_new();
			utils.book_append_sheet(
				book,
				audio,
				'Evaluación silencios'
			);
			writeFile(book, 'Repositorio_silencios.xlsx');
		}
		)
	}
}

